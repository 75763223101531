/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const partnerTheme = useContext(ThemeContext);
  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.getElementById('header-section-logo-link').focus({focusVisible : true});
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);
  const globalthemClasses = themeStyles(partnerTheme);

  const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained' || partnerTheme?.buttonStyle?.buttonType === 'outlined' || partnerTheme?.buttonStyle?.buttonType === 'text' ? partnerTheme?.buttonStyle?.buttonType : 'contained';
  let btnClassName;
  if (variantVal === 'contained') {
    btnClassName = globalthemClasses.containedBtn;
  } else if (variantVal === 'outlined') {
    btnClassName = globalthemClasses.outlinedBtn;
  } else {
    btnClassName = globalthemClasses.textBtn;
  }

  return (
    <div>
      {isVisible
        && (
        <div onClick={scrollToTop}>
          <Button id="scroll-btn"  variant={variantVal} size="large" className={clsx(`${btnClassName}`, globalthemClasses.scrollToTop)}>Top</Button>
        </div>
        )}
    </div>
  );
}
